import * as t from "io-ts";
import { useBaseQuery } from "../../../../Infrastructure/queryClient/useQuery";
import { validateAndDecode } from "../../../../Infrastructure/Services/ValidationUtil";

export const patientProfileAcousticSelectionBaseUrl = "PatientProfile/AcousticSelection?patientId=";

export const useAcousticCouplingsQuery = (patientId: string | undefined) => {
    return useBaseQuery<AcousticCouplingsData[]>(
        {
            url: `${patientProfileAcousticSelectionBaseUrl}${patientId}`,
            boundedContext: "PatientProfile",
            notifyOnChangeOn: patientId,
            validate: data => validateAndDecode(AcousticCouplingsDataArrayIO, data),
        },
        {
            enabled: !!patientId,
        }
    );
};

const AcousticCouplingsIO = t.type({
    receiver: t.union([t.number, t.nullType]),
    tipType: t.union([t.number, t.nullType]),
    ventSize: t.union([t.number, t.nullType]),
});

const AcousticCouplingsDataIO = t.intersection([
    AcousticCouplingsIO,
    t.type({
        deviceId: t.string,
    }),
]);
const AcousticCouplingsDataArrayIO = t.array(AcousticCouplingsDataIO);

export type AcousticCouplings = t.TypeOf<typeof AcousticCouplingsIO>;
export type AcousticCouplingsData = t.TypeOf<typeof AcousticCouplingsDataIO>;
