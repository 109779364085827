import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createRootStore } from "./reduxStore";
import QueryClientProvider from "./Infrastructure/queryClient/QueryClientProvider";
import { Spinner } from "@wsa/echo-components/dist";
import TelemetryInitializer from "./Infrastructure/Services/TelemetryInitializer";
import env from "@beam-australia/react-env";
import { AppInsightsTrackingBoundary } from "./Widex/AppInsightsTrackingBoundary";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate";

const store = createRootStore({
    enableLogging: process.env.NODE_ENV === "development",
});

export const appDispatch = store.dispatch;
export type AppDispatch = typeof appDispatch;

const Wrapper = ({ children }: { children: React.ReactNode }) => (
    <TelemetryInitializer instrumentationKey={env("APP_INSIGHT_KEY")}>
        <AppInsightsTrackingBoundary>
            <Provider store={store}>
                <QueryClientProvider>
                    <React.Suspense
                        fallback={
                            <div style={{ textAlign: "center" }}>
                                <Spinner branded={true} size="large" />
                            </div>
                        }>
                        <BrowserRouter basename={`${env("ROUTER_BASE_PATH")}`}>
                            <Auth0ProviderWithNavigate>{children}</Auth0ProviderWithNavigate>
                        </BrowserRouter>
                    </React.Suspense>
                </QueryClientProvider>
            </Provider>
        </AppInsightsTrackingBoundary>
    </TelemetryInitializer>
);

export default Wrapper;
