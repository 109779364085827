import React, { Component } from "react";
import { ai } from "./ApplicationInsights";
import Telemetry from "./Telemetry";
import { analyticsService } from "./AnalyticsService";
import { trackAnalyticsInitializer } from "@wsa/typescript-tracking-schemas";

interface TelemetryInitializerProps {
    instrumentationKey: string;
    children: React.ReactNode;
}

class TelemetryInitializer extends Component<TelemetryInitializerProps> {
    componentDidMount() {
        const { instrumentationKey } = this.props;
        if (instrumentationKey) {
            const applicationInsights = ai.initialize(instrumentationKey);
            const telemetryClient = new Telemetry(applicationInsights);
            trackAnalyticsInitializer.initalize(analyticsService.trackAnalyticsEvent);
            analyticsService.initalize(telemetryClient);
        }
    }

    render() {
        const { children } = this.props;
        return <div>{children}</div>;
    }
}

export default TelemetryInitializer;
